import React from 'react'
import { graphql } from 'gatsby'
import { Container, Button } from 'reactstrap'
import Layout from '../components/layout'
import Slider from '../components/slider/index'
import Testimonials from '../components/testimonials'
import Services from '../components/services.js'

const IndexPage = ({ data }) => (
  <>
    <Layout>
      <Slider height="600px" delay={5000} images={data.allFile.edges} />
      <section className="py-5">
        <Container>
          <h2 className="text-center text-uppercase">What they say about us</h2>
          <Testimonials />
        </Container>
      </section>
      <section className="pb-5 text-center">
        <Container>
          <h1 className="text-center text-uppercase mb-4">
            Professional Window and Gutter Cleaning in and around Chippenham
          </h1>
          <p className="lead">
            Greens Window Cleaning is a friendly company based in Chippenham. We
            use a sophisticated pure water window cleaning system that leaves
            your windows sparkling and streak free every time.
          </p>
          <p>
            We provide our services throughout Chippenham, Bath, and the
            surrounding areas. If you would like a free quote, please get in
            touch.
          </p>
          <Button className="my-4 w-75 m-auto bg-primary border-none" block>
            Request a free quote
          </Button>
        </Container>
      </section>
      <section className="py-5">
        <Container>
          <h2>Our Services</h2>
          <p>
            Greens take care of a wide range of cleaning services around the
            Chippenham and Bath area. With all our services, we use only the
            purest water, leaving your windows, gutters, fascias and soffits
            totally streak free. It’s also much better for the environment!
          </p>
          <Services />
        </Container>
      </section>
      <section className="py-5 bg-primary text-light">
        <Container>
          <h2 className="text-light">Why choose Greens window cleaning?</h2>
          <p>
            At Greens Window Cleaning Pure Water Reach and Wash System we take a
            personal approach when it comes to your windows. Instead of just
            using a traditional sponge, bucket and a squeegee we have a complete
            pure water system that will keep your windows cleaner for longer.
          </p>
          <p>
            Our service is fully mobile and is focused at working to your
            convenience, so we can clean your windows when you are not available
            or when it best suits you. We also don’t use ladders so we can
            ensure that we will not trample any of your flowers or scratch any
            of your property.
          </p>
        </Container>
      </section>
    </Layout>
  </>
)

export default IndexPage

export const Images = graphql`
  query {
    allFile(
      filter: { sourceInstanceName: { eq: "slides" }, extension: { eq: "md" } }
    ) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              captionHeader
              captionText
              captionButton
              image {
                childImageSharp {
                  fluid(maxWidth: 1000, quality: 90) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
