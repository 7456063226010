import { Link } from 'gatsby'
import styled from 'styled-components'

const Button = styled(Link)`
  display: inline-block;
  background: #28a745;
  padding: 0.3em 1em;
  margin-top: 1rem;
  color: #fff;
  border-radius: 0.5em;
  transition: all 0.5s ease;
  &:hover {
    text-decoration: none;
    color: white;
  }
`

const Submit = styled.button`
  background: #28a745;
  padding: 0.6em 2em;
  color: #fff;
  border: none;
  border-radius: 0.5em;
  transition: all 0.5s ease;
  cursor: pointer;
  &:hover {
    color: #fff;
    text-decoration: none;
  }
`
export { Button, Submit }
