import React, { useState, useEffect } from 'react'
import Wrapper from './sliderWrapper'
import Slide from './slide'
import { LeftArrow, RightArrow } from './sliderControl'

const Slider = ({ delay, images, height }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  useEffect(() => {
    const id = setTimeout(
      () =>
        setActiveIndex(activeIndex === images.length - 1 ? 0 : activeIndex + 1),
      delay || 3000
    )
    return () => clearTimeout(id)
  }, [activeIndex, delay, images.length])
  const goToNext = () => {
    setActiveIndex(activeIndex === images.length - 1 ? 0 : activeIndex + 1)
  }
  const goToPrevious = () => {
    setActiveIndex(activeIndex === 0 ? images.length - 1 : activeIndex - 1)
  }
  return (
    <>
      <Wrapper height={height}>
        <LeftArrow onClick={goToPrevious} />
        {images.map((item, index) => {
          const SlideInfo = item.node.childMarkdownRemark.frontmatter
          const image = SlideInfo.image.childImageSharp.fluid

          return (
            <Slide
              key={SlideInfo.captionText}
              active={activeIndex}
              image={image}
              captionHeader={SlideInfo.captionHeader}
              captionText={SlideInfo.captionText}
              captionButton={
                SlideInfo.captionButton ? SlideInfo.captionButton : null
              }
            />
          )
        })}
        <RightArrow onClick={goToNext} />
      </Wrapper>
    </>
  )
}
export default Slider
