import styled from 'styled-components'

const Arrow = styled.div`
  position: absolute;
  top: 50%;
  width: 20px;
  height: 20px;
  z-index: 100;
  border-top: 5px solid white;
  border-right: 5px solid white;
  opacity: 0.8;
  cursor: pointer;
  transition: transform 0.2s ease;
`
const LeftArrow = styled(Arrow)`
  left: 4%;
  transform: translateY(-50%) rotate(-135deg);
  &:hover,
  &:focus {
    transform: translateY(-50%) rotate(-135deg), scale(1.2);
  }
`
const RightArrow = styled(Arrow)`
  right: 4%;
  transform: translateY(-50%) rotate(45deg);
  &:hover,
  &:focus {
    transform: translateY(-50%) rotate(45deg) scale(1.2);
  }
`

export { LeftArrow, RightArrow }
