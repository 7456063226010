import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  height: ${props => props.height || `100vh`};
  width: 100%;
  overflow-x: hidden;
`

export default Wrapper
