import React from 'react'
import { Button } from '../button'
import styled from 'styled-components'
import Img from 'gatsby-image'

const StyledSlide = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  flex: 0 0 100%;
  transition: transform 0.6s ease;
  transform: translateX(-${props => 100 * props.active}%);
  .gatsby-image-wrapper {
    height: 100%;
  }
`
const Caption = styled.div`
  position: absolute;
  background: rgba(90, 90, 90, 0.8);
  padding: 40px 50px;
  width: 100%;
  bottom: 0;
  text-align: center;
  h3 {
    color: white;
  }
  h4 {
    color: white;
    margin: 0;
    font-size: 1.4rem;
  }
  @media (min-width: 800px) {
    width: 50%;
    left: 15%;
    top: 30%;
    bottom: auto;
    * {
      text-align: left;
    }
  }
`

const Slide = props => {
  return (
    <StyledSlide {...props}>
      <Img fluid={props.image} />
      <Caption>
        <h3>{props.captionHeader.toUpperCase()}</h3>
        <h4>{props.captionText}</h4>
        {props.captionButton && (
          <>
            <h4 className="mt-4 text-center">Call us on 01249 660268 or</h4>
            <Button className="mt-3" to="/quote">
              Request a quote
            </Button>
          </>
        )}
      </Caption>
    </StyledSlide>
  )
}

export default Slide
