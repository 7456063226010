import React from 'react'
import { graphql } from 'gatsby'
import { Row, Col } from 'reactstrap'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { useStaticQuery } from 'gatsby'

const ServiceImg = styled(Img)`
  border-radius: 50%;
`

export default function Services() {
  const data = useStaticQuery(graphql`
    query {
      fascia: file(relativePath: { eq: "fascia_cleaning.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gutter: file(relativePath: { eq: "gutter-cleaning.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      window: file(relativePath: { eq: "window-cleaning.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Row>
      <Col lg={4}>
        <div className="p-5">
          <ServiceImg fluid={data.window.childImageSharp.fluid} />
        </div>
        <h4>Professional Window Cleaning</h4>
        <p>
          Using our totally pure water cleaning system, we leave your windows
          totally streak free every time. And thanks to our 35ft poles, we can
          reach areas where others can’t.
        </p>
      </Col>
      <Col lg={4}>
        <div className="p-5">
          <ServiceImg fluid={data.fascia.childImageSharp.fluid} />
        </div>
        <h4>Gutter Cleaning external/internal</h4>
        <p>
          Internal gutter cleaning done with the latest 3 motor gutter vac to
          suck out leaves, moss and other debris. External cleaning available to
          remove green verdegre, dust and dirt, leaving your guttering clean and
          shiny.
        </p>
      </Col>
      <Col lg={4}>
        <div className="p-5">
          <ServiceImg fluid={data.gutter.childImageSharp.fluid} />
        </div>
        <h4>Soffits & Fascias Cleaning</h4>
        <p>
          Clean fascias and soffits can make a dramatic improvement to the
          overall appearance of your home. With our 30ft water fed pole system,
          we help make your fascias and soffits look brand new again.
        </p>
      </Col>
    </Row>
  )
}
