import React, { Component } from 'react'
import {
  Carousel,
  CarouselItem,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap'
import styled from 'styled-components'

const GreenCarousel = styled(Carousel)`
  .carousel-inner {
    height: 300px;
  }
  .carousel-indicators {
    li {
      background-color: ${props => props.theme.grey};
      &.active {
        background-color: ${props => props.theme.green};
      }
    }
  }
  .carousel-caption {
    h3 {
      color: ${props => props.theme.green};
      font-size: 1.5rem;
    }
    color: ${props => props.theme.grey};
    bottom: 50%;
    transform: translateY(50%);
    display: block !important;
    left: 0;
    right: 0;
  }
  .carousel-item,
  .carousel-item-next,
  .carousel-item-prev,
  .carousel-item-right,
  .carousel-item-left {
    height: 100%;
    align-items: center;
    &.active {
      display: flex;
    }
  }
`

const items = [
  {
    captionHeader: 'Very prompt, friendly & efficient service.',
    captionText: '- Susan Byatt',
    key: 1,
  },
  {
    captionHeader:
      'Would highly recommend Greens, a very professional service at a very reasonable price.',
    captionText: '- Amanda Tonks',
    key: 2,
  },
  {
    captionHeader:
      'Great service from Greens, very reasonable pricing – thoroughly recommend them.',
    captionText: '- Frances Critchlow',
    key: 3,
  },
  {
    captionHeader:
      'Excellent service. Reliable, professional and friendly. Always does a great job with the windows, very reasonably priced too. Would recommend.',
    captionText: '- John Wombat',
    key: 4,
  },
  {
    captionHeader: 'Very professional.',
    captionText: '- Mandy Withers',
    key: 5,
  },
]

class Testimonials extends Component {
  constructor(props) {
    super(props)
    this.state = { activeIndex: 0 }
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.goToIndex = this.goToIndex.bind(this)
    this.onExiting = this.onExiting.bind(this)
    this.onExited = this.onExited.bind(this)
  }

  onExiting() {
    this.animating = true
  }

  onExited() {
    this.animating = false
  }

  next() {
    if (this.animating) return
    const nextIndex =
      this.state.activeIndex === items.length - 1
        ? 0
        : this.state.activeIndex + 1
    this.setState({ activeIndex: nextIndex })
  }

  previous() {
    if (this.animating) return
    const nextIndex =
      this.state.activeIndex === 0
        ? items.length - 1
        : this.state.activeIndex - 1
    this.setState({ activeIndex: nextIndex })
  }

  goToIndex(newIndex) {
    if (this.animating) return
    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { activeIndex } = this.state

    const slides = items.map((item, index) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={index}
        >
          <CarouselCaption
            captionText={item.captionText}
            captionHeader={item.captionHeader}
          />
        </CarouselItem>
      )
    })

    return (
      <GreenCarousel
        pause="hover"
        activeIndex={activeIndex}
        next={this.next}
        previous={this.previous}
      >
        <CarouselIndicators
          items={items}
          activeIndex={activeIndex}
          onClickHandler={this.goToIndex}
        />
        {slides}
      </GreenCarousel>
    )
  }
}

export default Testimonials
